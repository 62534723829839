import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Typography, Button } from "@mui/material";
import { EN, RO } from "../../constants";
import { useTheme } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Import MUI Arrow Icon
import TodayIcon from "@mui/icons-material/Today";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/slices/languageSlice";

const BiblePlan = () => {
  const language = useAppSelector(selectLanguage);
  const theme = useTheme();
  const currentDateRef = useRef(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showScrollToToday, setShowScrollToToday] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScrollTop && window.pageYOffset > 400) {
      setShowScrollTop(true);
    } else if (showScrollTop && window.pageYOffset <= 400) {
      setShowScrollTop(false);
    }
  }, [showScrollTop]); // Dependencies for useCallback

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScrollTop, checkScrollTop]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkScrollToToday = () => {
    if (currentDateRef.current) {
      const rect = currentDateRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      setShowScrollToToday(!isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollToToday);
    return () => {
      window.removeEventListener("scroll", checkScrollToToday);
    };
  }, []);

  const scrollToTodayVerse = () => {
    if (currentDateRef.current) {
      const position = currentDateRef.current.offsetTop - 70; // Adjust as needed
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  };

  const scrollTopButtonStyle = {
    position: "fixed",
    right: "20px",
    bottom: "56px",
    zIndex: 100,
    opacity: "80%",
  };

  const scrollCurrentButtonStyle = {
    position: "fixed",
    left: "20px",
    top: "56px",
    zIndex: 100,
    opacity: "80%",
  };

  const mainStyle = {
    backgroundColor: "white",
    fontFamily: "Montserrat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    top: "56px",
    maxWidth: "100%",
    padding: "20px",
  };

  const monthHeaderStyle = {
    fontSize: "40px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    margin: "20px 0",
    textAlign: "center",
  };

  const dayPassageStyle = {
    textAlign: "left",
    width: "100%",
  };

  const introParagraphStyle = {
    margin: "0 20px", // Adding margin on the sides for paragraphs
    textAlign: "left",
    textIndent: "2em", // Adding indent for paragraphs
    marginBottom: "1em", // Increasing gap between paragraphs
  };

  const introNonParagraphStyle = {
    margin: "0 20px", // Adding margin on the sides for non-paragraphs
    textAlign: "left",
    marginBottom: "1em", // Space after the non-paragraph elements
  };

  const dayStyle = {
    fontWeight: "bold",
  };

  const pastDateStyle = {
    color: "gray",
  };

  const todayDateStyle = {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  };

  useEffect(() => {
    // Scroll to a position slightly above the current date element
    if (currentDateRef.current) {
      const position = currentDateRef.current.offsetTop - 70; // Adjust 100px or as needed for the header
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  }, []);

  const getDateStyle = (dateStr) => {
    const today = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const [month, day] = dateStr.split(" ");

    // Convert month name to month number
    const monthNumber = monthNames.indexOf(month);
    const dateToCheck = new Date(
      today.getFullYear(),
      monthNumber,
      parseInt(day, 10)
    );

    // Compare dates without time
    if (dateToCheck.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0))
      return pastDateStyle;
    if (dateToCheck.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))
      return todayDateStyle;
    return {};
  };

  // Determine the book based on the month and language
  const getBookForMonth = (month, lang) => {
    const bookMapping = {
      en: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
      ro: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
      },
    };

    return bookMapping[lang][month];
  };

  // Map for the book of Genesis and Exodus in English and Romanian for January
  const biblePassages = {
    en: {
      January: [
        "Genesis 1 to 3", // 1
        "Genesis 4 to 7", // 2
        "Genesis 8 to 10", // 3
        "Genesis 11 to 13", // 4
        "Genesis 14 to 17", // 5
        "Genesis 18 to 20", // 6
        "Genesis 21 to 23", // 7
        "Genesis 24 to 26", // 8
        "Genesis 27 to 29", // 9
        "Genesis 30 to 31", // 10
        "Genesis 32 to 34", // 11
        "Genesis 35 to 37", // 12
        "Genesis 38 to 40", // 13
        "Genesis 41 to 42", // 14
        "Genesis 43 to 44", // 15
        "Genesis 45 to 47", // 16
        "Genesis 48 to 50", // 17
        "Exodus 1 to 4", // 18
        "Exodus 5 to 8", // 19
        "Exodus 9 to 11", // 20
        "Exodus 12 to 14", // 21
        "Exodus 15 to 17", // 22
        "Exodus 18 to 21", // 23
        "Exodus 22 to 24", // 24
        "Exodus 25 to 27", // 25
        "Exodus 28 to 29", // 26
        "Exodus 30 to 32", // 27
        "Exodus 33 to 35", // 28
        "Exodus 36 to 37", // 29
        "Exodus 38 to 40", // 30
        "Leviticus 1 to 4", // 31
      ],
      February: [
        "Leviticus 5 to 7", // 1
        "Leviticus 8 to 10", // 2
        "Leviticus 11 to 13", // 3
        "Leviticus 14 to 15", // 4
        "Leviticus 16 to 18", // 5
        "Leviticus 19 to 21", // 6
        "Leviticus 22 to 23", // 7
        "Leviticus 24 to 25", // 8
        "Leviticus 26 to 27", // 9
        "Numbers 1 to 2", // 10
        "Numbers 3 to 5", // 11
        "Numbers 6 to 7", // 12
        "Numbers 8 to 9", // 13
        "Numbers 10 to 12", // 14
        "Numbers 13 to 14", // 15
        "Numbers 15 to 16", // 16
        "Numbers 17 to 20", // 17
        "Numbers 21 to 23", // 18
        "Numbers 24 to 26", // 19
        "Numbers 27 to 28", // 20
        "Numbers 29 to 31", // 21
        "Numbers 32 to 33", // 22
        "Numbers 34 to 36", // 23
        "Deuteronomy 1 to 3", // 24
        "Deuteronomy 4 to 6", // 25
        "Deuteronomy 7 to 9", // 26
        "Deuteronomy 10 to 12", // 27
        "Deuteronomy 13 to 16", // 28
      ],
      March: [
        "Deuteronomy 17 to 19", // 1
        "Deuteronomy 20 to 22", // 2
        "Deuteronomy 23 to 26", // 3
        "Deuteronomy 27 to 28", // 4
        "Deuteronomy 29 to 31", // 5
        "Deuteronomy 32 to 34", // 6
        "Joshua 1 to 4", // 7
        "Joshua 5 to 8", // 8
        "Joshua 9 to 11", // 9
        "Joshua 12 to 14", // 10
        "Joshua 15 to 17", // 11
        "Joshua 18 to 21", // 12
        "Joshua 22 to 24", // 13
        "Judges 1 to 2", // 14
        "Judges 3 to 5", // 15
        "Judges 6 to 7", // 16
        "Judges 8 to 9", // 17
        "Judges 10 to 13", // 18
        "Judges 14 to 16", // 19
        "Judges 17 to 18", // 20
        "Judges 19 to 21", // 21
        "Ruth 1 to 4", // 22
        "1 Samuel 1 to 4", // 23
        "1 Samuel 5 to 8", // 24
        "1 Samuel 9 to 11", // 25
        "1 Samuel 12 to 14", // 26
        "1 Samuel 15 to 17", // 27
        "1 Samuel 18 to 19", // 28
        "1 Samuel 20 to 22", // 29
        "1 Samuel 23 to 25", // 30
        "1 Samuel 26 to 29", // 31
      ],
      April: [
        "1 Samuel 30 to 31", // 1
        "2 Samuel 1 to 5", // 2
        "2 Samuel 6 to 10", // 3
        "2 Samuel 11 to 13", // 4
        "2 Samuel 14 to 16", // 5
        "2 Samuel 17 to 19", // 6
        "2 Samuel 20 to 21", // 7
        "2 Samuel 22 to 23", // 8
        "2 Samuel 24", // 9
        "1 Kings 1 to 3", // 10
        "1 Kings 4 to 6", // 11
        "1 Kings 7 to 9", // 12
        "1 Kings 10 to 11", // 13
        "1 Kings 12 to 13", // 14
        "1 Kings 14 to 16", // 15
        "1 Kings 17 to 19", // 16
        "1 Kings 20 to 22", // 17
        "2 Kings 1 to 5", // 18
        "2 Kings 6 to 9", // 19
        "2 Kings 10 to 13", // 20
        "2 Kings 14 to 16", // 21
        "2 Kings 17 to 19", // 22
        "2 Kings 20 to 22", // 23
        "2 Kings 23 to 25", // 24
        "1 Chronicles 1 to 3", // 25
        "1 Chronicles 4 to 6", // 26
        "1 Chronicles 7 to 8", // 27
        "1 Chronicles 9 to 11", // 28
        "1 Chronicles 12 to 15", // 29
      ],
      May: [
        "1 Chronicles 16 to 18", // 1
        "1 Chronicles 19 to 22", // 2
        "1 Chronicles 23 to 25", // 3
        "1 Chronicles 26 to 27", // 4
        "1 Chronicles 28 to 29", // 5
        "2 Chronicles 1 to 5", // 6
        "2 Chronicles 6 to 10", // 7
        "2 Chronicles 11 to 15", // 8
        "2 Chronicles 16 to 19", // 9
        "2 Chronicles 20 to 23", // 10
        "2 Chronicles 24 to 28", // 11
        "2 Chronicles 29 to 31", // 12
        "2 Chronicles 32 to 34", // 13
        "2 Chronicles 35 to 36", // 14
        "Ezra 1 to 5", // 15
        "Ezra 6 to 10", // 16
        "Nehemiah 1 to 4", // 17
        "Nehemiah 5 to 8", // 18
        "Nehemiah 9 to 11", // 19
        "Nehemiah 12 to 13", // 20
        "Esther 1 to 4", // 21
        "Esther 5 to 10", // 22
        "Job 1 to 4", // 23
        "Job 5 to 8", // 24
        "Job 9 to 11", // 25
        "Job 12 to 15", // 26
        "Job 16 to 19", // 27
        "Job 20 to 24", // 28
        "Job 25 to 28", // 29
        "Job 29 to 31", // 30
        "Job 32 to 35", // 31
      ],
      June: [
        "Job 36 to 39", // 1
        "Job 40 to 42", // 2
        "Psalms 1 to 4", // 3
        "Psalms 5 to 8", // 4
        "Psalms 9 to 10", // 5
        "Psalms 11 to 16", // 6
        "Psalms 17 to 18", // 7
        "Psalms 19 to 21", // 8
        "Psalms 22 to 23", // 9
        "Psalms 24 to 26", // 10
        "Psalms 27 to 30", // 11
        "Psalms 31 to 32", // 12
        "Psalms 33 to 35", // 13
        "Psalms 36 to 37", // 14
        "Psalms 38 to 39", // 15
        "Psalms 40 to 42", // 16
        "Psalms 43 to 44", // 17
        "Psalms 45 to 47", // 18
        "Psalms 48 to 50", // 19
        "Psalms 51 to 54", // 20
        "Psalms 55 to 57", // 21
        "Psalms 58 to 60", // 22
        "Psalms 61 to 64", // 23
        "Psalms 65 to 66", // 24
        "Psalms 67 to 69", // 25
        "Psalms 70 to 71", // 26
        "Psalms 72 to 73", // 27
        "Psalms 74 to 75", // 28
        "Psalms 76 to 77", // 29
        "Psalms 78 to 79", // 30
      ],
      July: [
        "Psalms 80 to 82", // 1
        "Psalms 83 to 86", // 2
        "Psalms 87 to 89", // 3
        "Psalms 90 to 91", // 4
        "Psalms 92 to 94", // 5
        "Psalms 95 to 97", // 6
        "Psalms 98 to 101", // 7
        "Psalms 102 to 103", // 8
        "Psalms 104 to 105", // 9
        "Psalms 106", // 10
        "Psalms 107", // 11
        "Psalms 108 to 109", // 12
        "Psalms 110 to 113", // 13
        "Psalms 114 to 115", // 14
        "Psalms 116 to 118", // 15
        "Psalms 119", // 16
        "Psalms 120 to 122", // 17
        "Psalms 123 to 125", // 18
        "Psalms 126 to 131", // 19
        "Psalms 132 to 134", // 20
        "Psalms 135 to 137", // 21
        "Psalms 138 to 140", // 22
        "Psalms 141 to 144", // 23
        "Psalms 145 to 147", // 24
        "Psalms 148 to 150", // 25
        "Proverbs 1 to 4", // 26
        "Proverbs 5 to 8", // 27
        "Proverbs 9 to 11", // 28
        "Proverbs 12 to 13", // 29
        "Proverbs 14 to 16", // 30
        "Proverbs 17 to 20", // 31
      ],
      August: [
        "Proverbs 21 to 24", // 1
        "Proverbs 25 to 27", // 2
        "Proverbs 28 to 31", // 3
        "Ecclesiastes 1 to 6", // 4
        "Ecclesiastes 7 to 12", // 5
        "Song of Solomon 1 to 4", // 6
        "Song of Solomon 5 to 8", // 7
        "Isaiah 1 to 4", // 8
        "Isaiah 5 to 8", // 9
        "Isaiah 9 to 12", // 10
        "Isaiah 13 to 18", // 11
        "Isaiah 19 to 23", // 12
        "Isaiah 24 to 27", // 13
        "Isaiah 28 to 32", // 14
        "Isaiah 33 to 37", // 15
        "Isaiah 38 to 40", // 16
        "Isaiah 41 to 44", // 17
        "Isaiah 45 to 49", // 18
        "Isaiah 50 to 54", // 19
        "Isaiah 55 to 61", // 20
        "Isaiah 62 to 66", // 21
        "Jeremiah 1 to 2", // 22
        "Jeremiah 3 to 4", // 23
        "Jeremiah 5 to 8", // 24
        "Jeremiah 9 to 12", // 25
        "Jeremiah 13 to 15", // 26
        "Jeremiah 16 to 20", // 27
        "Jeremiah 21 to 24", // 28
        "Jeremiah 25 to 27", // 29
        "Jeremiah 28 to 30", // 30
        "Jeremiah 31 to 33", // 31
      ],
      September: [
        "Jeremiah 34 to 36", // 1
        "Jeremiah 37 to 40", // 2
        "Jeremiah 41 to 45", // 3
        "Jeremiah 46 to 48", // 4
        "Jeremiah 49 to 50", // 5
        "Jeremiah 51 to 52", // 6
        "Lamentations 1 to 3", // 7
        "Lamentations 4 to 5", // 8
        "Ezekiel 1 to 5", // 9
        "Ezekiel 6 to 12", // 10
        "Ezekiel 13 to 16", // 11
        "Ezekiel 17 to 19", // 12
        "Ezekiel 20 to 22", // 13
        "Ezekiel 23 to 24", // 14
        "Ezekiel 25 to 27", // 15
        "Ezekiel 28 to 31", // 16
        "Ezekiel 32 to 34", // 17
        "Ezekiel 35 to 38", // 18
        "Ezekiel 39 to 40", // 19
        "Ezekiel 41 to 44", // 20
        "Ezekiel 45 to 48", // 21
        "Daniel 1 to 3", // 22
        "Daniel 4 to 6", // 23
        "Daniel 7 to 9", // 24
        "Daniel 10 to 12", // 25
        "Hosea 1 to 3", // 26
        "Hosea 4 to 9", // 27
        "Hosea 10 to 14", // 28
        "Joel 1 to Amos 4", // 29
        "Amos 5 to 9", // 30
      ],
      October: [
        "Obadiah, Jonah, and Micah 1 to 3", // 1
        "Micah 4 to 7 and Nahum 1 to 2", // 2
        "Nahum 3 and Habakkuk", // 3
        "Zephaniah and Haggai", // 4
        "Zechariah 1 to 7", // 5
        "Zechariah 8 to 14", // 6
        "Malachi", // 7
        "Matthew 1 to 4", // 8
        "Matthew 5 to 7", // 9
        "Matthew 8 to 10", // 10
        "Matthew 11 to 12", // 11
        "Matthew 13 to 14", // 12
        "Matthew 15 to 17", // 13
        "Matthew 18 to 20", // 14
        "Matthew 21 to 22", // 15
        "Matthew 23 to 24", // 16
        "Matthew 25 to 26", // 17
        "Matthew 27 to 28", // 18
        "Mark 1 to 3", // 19
        "Mark 4 to 5", // 20
        "Mark 6 to 7", // 21
        "Mark 8 to 9", // 22
        "Mark 10 to 11", // 23
        "Mark 12 to 13", // 24
        "Mark 14 to 16", // 25
        "Luke 1 to 2", // 26
        "Luke 3 to 4", // 27
        "Luke 5 to 6", // 28
        "Luke 7 to 8", // 29
        "Luke 9 to 10", // 30
        "Luke 11 to 12", // 31
      ],
      November: [
        "Luke 13 to 14", // 1
        "Luke 15 to 16", // 2
        "Luke 17 to 19", // 3
        "Luke 20 to 21", // 4
        "Luke 22 to 23", // 5
        "Luke 24", // 6
        "John 1 to 2", // 7
        "John 3 to 4", // 8
        "John 5 to 6", // 9
        "John 7 to 8", // 10
        "John 9 to 10", // 11
        "John 11 to 12", // 12
        "John 13 to 14", // 13
        "John 15 to 17", // 14
        "John 18 to 19", // 15
        "John 20 to 21", // 16
        "Acts 1 to 3", // 17
        "Acts 4 to 7", // 18
        "Acts 8 to 10", // 19
        "Acts 11 to 13", // 20
        "Acts 14 to 16", // 21
        "Acts 17 to 18", // 22
        "Acts 19 to 20", // 23
        "Acts 21 to 22", // 24
        "Acts 23 to 25", // 25
        "Acts 26 to 28", // 26
        "Romans 1 to 3", // 27
        "Romans 4 to 6", // 28
        "Romans 7 to 9", // 29
        "Romans 10 to 12", // 30
      ],
      December: [
        "Romans 13 to 16", // 1
        "1 Corinthians 1 to 4", // 2
        "1 Corinthians 5 to 8", // 3
        "1 Corinthians 9 to 12", // 4
        "1 Corinthians 13 to 16", // 5
        "2 Corinthians 1 to 4", // 6
        "2 Corinthians 5 to 9", // 7
        "2 Corinthians 10 to 13", // 8
        "Galatians 1 to 3", // 9
        "Galatians 4 to 6", // 10
        "Ephesians 1 to 3", // 11
        "Ephesians 4 to 6", // 12
        "Philippians", // 13
        "Colossians", // 14
        "1 Thessalonians and 2 Thessalonians", // 15
        "1 Timothy", // 16
        "2 Timothy", // 17
        "Titus and Philemon", // 18
        "Hebrews 1 to 5", // 19
        "Hebrews 6 to 13", // 20
        "James", // 21
        "1 Peter", // 22
        "2 Peter", // 23
        "1 John 1 to 2", // 24
        "1 John 3 to 5", // 25
        "2 John and 3 John", // 26
        "Jude", // 27
        "Revelation 1 to 4", // 28
        "Revelation 5 to 12", // 29
        "Revelation 13 to 17", // 30
        "Revelation 18 to 22", // 31
      ],
    },
    ro: {
      January: [
        "Geneza 1 până la 3", // 1
        "Geneza 4 până la 7", // 2
        "Geneza 8 până la 10", // 3
        "Geneza 11 până la 13", // 4
        "Geneza 14 până la 17", // 5
        "Geneza 18 până la 20", // 6
        "Geneza 21 până la 23", // 7
        "Geneza 24 până la 26", // 8
        "Geneza 27 până la 29", // 9
        "Geneza 30 până la 31", // 10
        "Geneza 32 până la 34", // 11
        "Geneza 35 până la 37", // 12
        "Geneza 38 până la 40", // 13
        "Geneza 41 până la 42", // 14
        "Geneza 43 până la 44", // 15
        "Geneza 45 până la 47", // 16
        "Geneza 48 până la 50", // 17
        "Exodul 1 până la 4", // 18
        "Exodul 5 până la 8", // 19
        "Exodul 9 până la 11", // 20
        "Exodul 12 până la 14", // 21
        "Exodul 15 până la 17", // 22
        "Exodul 18 până la 21", // 23
        "Exodul 22 până la 24", // 24
        "Exodul 25 până la 27", // 25
        "Exodul 28 până la 29", // 26
        "Exodul 30 până la 32", // 27
        "Exodul 33 până la 35", // 28
        "Exodul 36 până la 37", // 29
        "Exodul 38 până la 40", // 30
        "Leviticul 1 până la 4", // 31
      ],
      February: [
        "Leviticul 5 până la 7", // 1
        "Leviticul 8 până la 10", // 2
        "Leviticul 11 până la 13", // 3
        "Leviticul 14 până la 15", // 4
        "Leviticul 16 până la 18", // 5
        "Leviticul 19 până la 21", // 6
        "Leviticul 22 până la 23", // 7
        "Leviticul 24 până la 25", // 8
        "Leviticul 26 până la 27", // 9
        "Numeri 1 până la 2", // 10
        "Numeri 3 până la 5", // 11
        "Numeri 6 până la 7", // 12
        "Numeri 8 până la 9", // 13
        "Numeri 10 până la 12", // 14
        "Numeri 13 până la 14", // 15
        "Numeri 15 până la 16", // 16
        "Numeri 17 până la 20", // 17
        "Numeri 21 până la 23", // 18
        "Numeri 24 până la 26", // 19
        "Numeri 27 până la 28", // 20
        "Numeri 29 până la 31", // 21
        "Numeri 32 până la 33", // 22
        "Numeri 34 până la 36", // 23
        "Deuteronomul 1 până la 3", // 24
        "Deuteronomul 4 până la 6", // 25
        "Deuteronomul 7 până la 9", // 26
        "Deuteronomul 10 până la 12", // 27
        "Deuteronomul 13 până la 16", // 28
      ],
      March: [
        "Deuteronomul 17 până la 19", // 1
        "Deuteronomul 20 până la 22", // 2
        "Deuteronomul 23 până la 26", // 3
        "Deuteronomul 27 până la 28", // 4
        "Deuteronomul 29 până la 31", // 5
        "Deuteronomul 32 până la 34", // 6
        "Iosua 1 până la 4", // 7
        "Iosua 5 până la 8", // 8
        "Iosua 9 până la 11", // 9
        "Iosua 12 până la 14", // 10
        "Iosua 15 până la 17", // 11
        "Iosua 18 până la 21", // 12
        "Iosua 22 până la 24", // 13
        "Judecători 1 până la 2", // 14
        "Judecători 3 până la 5", // 15
        "Judecători 6 până la 7", // 16
        "Judecători 8 până la 9", // 17
        "Judecători 10 până la 13", // 18
        "Judecători 14 până la 16", // 19
        "Judecători 17 până la 18", // 20
        "Judecători 19 până la 21", // 21
        "Rut 1 până la 4", // 22
        "1 Samuel 1 până la 4", // 23
        "1 Samuel 5 până la 8", // 24
        "1 Samuel 9 până la 11", // 25
        "1 Samuel 12 până la 14", // 26
        "1 Samuel 15 până la 17", // 27
        "1 Samuel 18 până la 19", // 28
        "1 Samuel 20 până la 22", // 29
        "1 Samuel 23 până la 25", // 30
        "1 Samuel 26 până la 29", // 31
      ],
      April: [
        "1 Samuel 30 până la 31", // 1
        "2 Samuel 1 până la 5", // 2
        "2 Samuel 6 până la 10", // 3
        "2 Samuel 11 până la 13", // 4
        "2 Samuel 14 până la 16", // 5
        "2 Samuel 17 până la 19", // 6
        "2 Samuel 20 până la 21", // 7
        "2 Samuel 22 până la 23", // 8
        "2 Samuel 24", // 9
        "1 Împărați 1 până la 3", // 10
        "1 Împărați 4 până la 6", // 11
        "1 Împărați 7 până la 9", // 12
        "1 Împărați 10 până la 11", // 13
        "1 Împărați 12 până la 13", // 14
        "1 Împărați 14 până la 16", // 15
        "1 Împărați 17 până la 19", // 16
        "1 Împărați 20 până la 22", // 17
        "2 Împărați 1 până la 5", // 18
        "2 Împărați 6 până la 9", // 19
        "2 Împărați 10 până la 13", // 20
        "2 Împărați 14 până la 16", // 21
        "2 Împărați 17 până la 19", // 22
        "2 Împărați 20 până la 22", // 23
        "2 Împărați 23 până la 25", // 24
        "1 Cronici 1 până la 3", // 25
        "1 Cronici 4 până la 6", // 26
        "1 Cronici 7 până la 8", // 27
        "1 Cronici 9 până la 11", // 28
        "1 Cronici 12 până la 15", // 29
      ],
      May: [
        "1 Cronici 16 până la 18", // 1
        "1 Cronici 19 până la 22", // 2
        "1 Cronici 23 până la 25", // 3
        "1 Cronici 26 până la 27", // 4
        "1 Cronici 28 până la 29", // 5
        "2 Cronici 1 până la 5", // 6
        "2 Cronici 6 până la 10", // 7
        "2 Cronici 11 până la 15", // 8
        "2 Cronici 16 până la 19", // 9
        "2 Cronici 20 până la 23", // 10
        "2 Cronici 24 până la 28", // 11
        "2 Cronici 29 până la 31", // 12
        "2 Cronici 32 până la 34", // 13
        "2 Cronici 35 până la 36", // 14
        "Ezra 1 până la 5", // 15
        "Ezra 6 până la 10", // 16
        "Neemia 1 până la 4", // 17
        "Neemia 5 până la 8", // 18
        "Neemia 9 până la 11", // 19
        "Neemia 12 până la 13", // 20
        "Estera 1 până la 4", // 21
        "Estera 5 până la 10", // 22
        "Iov 1 până la 4", // 23
        "Iov 5 până la 8", // 24
        "Iov 9 până la 11", // 25
        "Iov 12 până la 15", // 26
        "Iov 16 până la 19", // 27
        "Iov 20 până la 24", // 28
        "Iov 25 până la 28", // 29
        "Iov 29 până la 31", // 30
        "Iov 32 până la 35", // 31
      ],
      June: [
        "Iov 36 până la 39", // 1
        "Iov 40 până la 42", // 2
        "Psalmi 1 până la 4", // 3
        "Psalmi 5 până la 8", // 4
        "Psalmi 9 până la 10", // 5
        "Psalmi 11 până la 16", // 6
        "Psalmi 17 până la 18", // 7
        "Psalmi 19 până la 21", // 8
        "Psalmi 22 până la 23", // 9
        "Psalmi 24 până la 26", // 10
        "Psalmi 27 până la 30", // 11
        "Psalmi 31 până la 32", // 12
        "Psalmi 33 până la 35", // 13
        "Psalmi 36 până la 37", // 14
        "Psalmi 38 până la 39", // 15
        "Psalmi 40 până la 42", // 16
        "Psalmi 43 până la 44", // 17
        "Psalmi 45 până la 47", // 18
        "Psalmi 48 până la 50", // 19
        "Psalmi 51 până la 54", // 20
        "Psalmi 55 până la 57", // 21
        "Psalmi 58 până la 60", // 22
        "Psalmi 61 până la 64", // 23
        "Psalmi 65 până la 66", // 24
        "Psalmi 67 până la 69", // 25
        "Psalmi 70 până la 71", // 26
        "Psalmi 72 până la 73", // 27
        "Psalmi 74 până la 75", // 28
        "Psalmi 76 până la 77", // 29
        "Psalmi 78 până la 79", // 30
      ],
      July: [
        "Psalmi 80 până la 82", // 1
        "Psalmi 83 până la 86", // 2
        "Psalmi 87 până la 89", // 3
        "Psalmi 90 până la 91", // 4
        "Psalmi 92 până la 94", // 5
        "Psalmi 95 până la 97", // 6
        "Psalmi 98 până la 101", // 7
        "Psalmi 102 până la 103", // 8
        "Psalmi 104 până la 105", // 9
        "Psalmi 106", // 10
        "Psalmi 107", // 11
        "Psalmi 108 până la 109", // 12
        "Psalmi 110 până la 113", // 13
        "Psalmi 114 până la 115", // 14
        "Psalmi 116 până la 118", // 15
        "Psalmi 119", // 16
        "Psalmi 120 până la 122", // 17
        "Psalmi 123 până la 125", // 18
        "Psalmi 126 până la 131", // 19
        "Psalmi 132 până la 134", // 20
        "Psalmi 135 până la 137", // 21
        "Psalmi 138 până la 140", // 22
        "Psalmi 141 până la 144", // 23
        "Psalmi 145 până la 147", // 24
        "Psalmi 148 până la 150", // 25
        "Proverbe 1 până la 4", // 26
        "Proverbe 5 până la 8", // 27
        "Proverbe 9 până la 11", // 28
        "Proverbe 12 până la 13", // 29
        "Proverbe 14 până la 16", // 30
        "Proverbe 17 până la 20", // 31
      ],
      August: [
        "Proverbe 21 până la 24", // 1
        "Proverbe 25 până la 27", // 2
        "Proverbe 28 până la 31", // 3
        "Eclesiastul 1 până la 6", // 4
        "Eclesiastul 7 până la 12", // 5
        "Cântarea Cântărilor 1 până la 4", // 6
        "Cântarea Cântărilor 5 până la 8", // 7
        "Isaia 1 până la 4", // 8
        "Isaia 5 până la 8", // 9
        "Isaia 9 până la 12", // 10
        "Isaia 13 până la 18", // 11
        "Isaia 19 până la 23", // 12
        "Isaia 24 până la 27", // 13
        "Isaia 28 până la 32", // 14
        "Isaia 33 până la 37", // 15
        "Isaia 38 până la 40", // 16
        "Isaia 41 până la 44", // 17
        "Isaia 45 până la 49", // 18
        "Isaia 50 până la 54", // 19
        "Isaia 55 până la 61", // 20
        "Isaia 62 până la 66", // 21
        "Ieremia 1 până la 2", // 22
        "Ieremia 3 până la 4", // 23
        "Ieremia 5 până la 8", // 24
        "Ieremia 9 până la 12", // 25
        "Ieremia 13 până la 15", // 26
        "Ieremia 16 până la 20", // 27
        "Ieremia 21 până la 24", // 28
        "Ieremia 25 până la 27", // 29
        "Ieremia 28 până la 30", // 30
        "Ieremia 31 până la 33", // 31
      ],
      September: [
        "Ieremia 34 până la 36", // 1
        "Ieremia 37 până la 40", // 2
        "Ieremia 41 până la 45", // 3
        "Ieremia 46 până la 48", // 4
        "Ieremia 49 până la 50", // 5
        "Ieremia 51 până la 52", // 6
        "Plângerile 1 până la 3", // 7
        "Plângerile 4 până la 5", // 8
        "Ezechiel 1 până la 5", // 9
        "Ezechiel 6 până la 12", // 10
        "Ezechiel 13 până la 16", // 11
        "Ezechiel 17 până la 19", // 12
        "Ezechiel 20 până la 22", // 13
        "Ezechiel 23 până la 24", // 14
        "Ezechiel 25 până la 27", // 15
        "Ezechiel 28 până la 31", // 16
        "Ezechiel 32 până la 34", // 17
        "Ezechiel 35 până la 38", // 18
        "Ezechiel 39 până la 40", // 19
        "Ezechiel 41 până la 44", // 20
        "Ezechiel 45 până la 48", // 21
        "Daniel 1 până la 3", // 22
        "Daniel 4 până la 6", // 23
        "Daniel 7 până la 9", // 24
        "Daniel 10 până la 12", // 25
        "Osea 1 până la 3", // 26
        "Osea 4 până la 9", // 27
        "Osea 10 până la 14", // 28
        "Ioel 1 până la Amos 4", // 29
        "Amos 5 până la 9", // 30
      ],
      October: [
        "Obadia, Iona și Mica 1 până la 3", // 1
        "Mica 4 până la 7 și Naum 1 până la 2", // 2
        "Naum 3 și Habacuc", // 3
        "Țefania și Hagai", // 4
        "Zaharia 1 până la 7", // 5
        "Zaharia 8 până la 14", // 6
        "Maleahi", // 7
        "Matei 1 până la 4", // 8
        "Matei 5 până la 7", // 9
        "Matei 8 până la 10", // 10
        "Matei 11 până la 12", // 11
        "Matei 13 până la 14", // 12
        "Matei 15 până la 17", // 13
        "Matei 18 până la 20", // 14
        "Matei 21 până la 22", // 15
        "Matei 23 până la 24", // 16
        "Matei 25 până la 26", // 17
        "Matei 27 până la 28", // 18
        "Marcu 1 până la 3", // 19
        "Marcu 4 până la 5", // 20
        "Marcu 6 până la 7", // 21
        "Marcu 8 până la 9", // 22
        "Marcu 10 până la 11", // 23
        "Marcu 12 până la 13", // 24
        "Marcu 14 până la 16", // 25
        "Luca 1 până la 2", // 26
        "Luca 3 până la 4", // 27
        "Luca 5 până la 6", // 28
        "Luca 7 până la 8", // 29
        "Luca 9 până la 10", // 30
        "Luca 11 până la 12", // 31
      ],
      November: [
        "Luca 13 până la 14", // 1
        "Luca 15 până la 16", // 2
        "Luca 17 până la 19", // 3
        "Luca 20 până la 21", // 4
        "Luca 22 până la 23", // 5
        "Luca 24", // 6
        "Ioan 1 până la 2", // 7
        "Ioan 3 până la 4", // 8
        "Ioan 5 până la 6", // 9
        "Ioan 7 până la 8", // 10
        "Ioan 9 până la 10", // 11
        "Ioan 11 până la 12", // 12
        "Ioan 13 până la 14", // 13
        "Ioan 15 până la 17", // 14
        "Ioan 18 până la 19", // 15
        "Ioan 20 până la 21", // 16
        "Faptele Apostolilor 1 până la 3", // 17
        "Faptele Apostolilor 4 până la 7", // 18
        "Faptele Apostolilor 8 până la 10", // 19
        "Faptele Apostolilor 11 până la 13", // 20
        "Faptele Apostolilor 14 până la 16", // 21
        "Faptele Apostolilor 17 până la 18", // 22
        "Faptele Apostolilor 19 până la 20", // 23
        "Faptele Apostolilor 21 până la 22", // 24
        "Faptele Apostolilor 23 până la 25", // 25
        "Faptele Apostolilor 26 până la 28", // 26
        "Romani 1 până la 3", // 27
        "Romani 4 până la 6", // 28
        "Romani 7 până la 9", // 29
        "Romani 10 până la 12", // 30
      ],
      December: [
        "Romani 13 până la 16", // 1
        "1 Corinteni 1 până la 4", // 2
        "1 Corinteni 5 până la 8", // 3
        "1 Corinteni 9 până la 12", // 4
        "1 Corinteni 13 până la 16", // 5
        "2 Corinteni 1 până la 4", // 6
        "2 Corinteni 5 până la 9", // 7
        "2 Corinteni 10 până la 13", // 8
        "Galateni 1 până la 3", // 9
        "Galateni 4 până la 6", // 10
        "Efeseni 1 până la 3", // 11
        "Efeseni 4 până la 6", // 12
        "Filipeni", // 13
        "Coloseni", // 14
        "1 Tesaloniceni și 2 Tesaloniceni", // 15
        "1 Timotei", // 16
        "2 Timotei", // 17
        "Tit și Filimon", // 18
        "Evrei 1 până la 5", // 19
        "Evrei 6 până la 13", // 20
        "Iacov", // 21
        "1 Petru", // 22
        "2 Petru", // 23
        "1 Ioan 1 până la 2", // 24
        "1 Ioan 3 până la 5", // 25
        "2 Ioan și 3 Ioan", // 26
        "Iuda", // 27
        "Apocalipsa 1 până la 4", // 28
        "Apocalipsa 5 până la 12", // 29
        "Apocalipsa 13 până la 17", // 30
        "Apocalipsa 18 până la 22", // 31
      ],
    },
  };

  const renderMonth = (month, days) => {
    const book = getBookForMonth(month, language === RO ? RO : EN);

    return (
      <div>
        <Typography style={monthHeaderStyle}>{month}</Typography>
        {days.map((day, index) => {
          const isToday = getDateStyle(day) === todayDateStyle;
          return (
            <Typography
              key={index}
              variant="h6"
              style={{ ...dayPassageStyle, ...getDateStyle(day) }}
              ref={isToday ? currentDateRef : null}
            >
              <span style={dayStyle}>{day} - </span>
              {biblePassages[language === RO ? RO : EN][book][index]}
            </Typography>
          );
        })}
      </div>
    );
  };

  return (
    <div style={mainStyle}>
      <Typography
        variant="h1"
        sx={{
          fontSize: {
            xs: "3rem", // small devices
            sm: "3rem", // medium devices
            md: "4rem", // large devices
            lg: "4rem", // extra-large devices
            xl: "5rem", // extra-extra-large devices
          },
          marginBottom: "10px",
        }}
      >
        {language === EN ? "Bible Reading Plan" : "Plan de Citire a Bibliei"}
      </Typography>

      <Divider light width="100%" sx={{ marginBottom: "15px" }} />
      <Typography style={introNonParagraphStyle}>
        {language === EN
          ? "Dear Brothers and Sisters,"
          : "Dragi frați și surori,"}
      </Typography>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "As we begin to read Holy Scripture, that is God's own inerrant Word, let us member the authority of Scripture is a key issue for all Christians in this and every age. Those who profess faith in Jesus Christ as Lord and Savior are called to show the reality of their discipleship by humbly and faithfully obeying God's written Word. To stray from Scripture in faith and practice is disloyalty to our Master Jesus Christ."
            : "Pe măsură ce începem să citim Sfânta Scriptură, care este Cuvântul infailibil al lui Dumnezeu, să ne amintim că autoritatea Scripturii este o problemă cheie pentru toți creștinii în această epocă și în toate celelalte. Cei care mărturisesc credința în Isus Hristos ca Domn și Mântuitor sunt chemați să arate realitatea discipolatului lor prin ascultarea umilă și credincioasă a Cuvântului scris al lui Dumnezeu. A ne abate de la Scriptură în credință și practică este o neloialitate față de Stăpânul nostru Isus Hristos."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "Let us remember that God, who is Himself Truth and speaks truth only, has inspired Holy Scripture in order thereby to reveal Himself to lost mankind through Jesus Christ as Creator and Lord, Redeemer, and ludge. Holy Scripture is God's witness to Himself."
            : "Să ne amintim că Dumnezeu, care este Însuși Adevărul și vorbește doar adevărul, a inspirat Sfânta Scriptură pentru a Se revela astfel omenirii pierdute prin Isus Hristos ca Creator și Domn, Răscumpărător și Judecător. Sfânta Scriptură este mărturia lui Dumnezeu despre Sine Însuși."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "Scripture is without error or fault in all its teaching, no less in what it states about God's acts in creation, about the events of world history, and about its own literary origins under God, than in its witness to God's saving grace in individual lives."
            : "Scriptura este fără eroare sau cusur în toate învățăturile sale, nu mai puțin în ceea ce afirmă despre actele lui Dumnezeu în creație, despre evenimentele istoriei lumii și despre propriile sale origini literare sub Dumnezeu, decât în mărturia sa despre harul salvator al lui Dumnezeu în viețile individuale."}
        </Typography>
      </div>

      <div style={introParagraphStyle}>
        <Typography>
          {language === EN
            ? "I look forward to reading God's Holy Word together; word by word, verse by verse, chapter by chapter, and book by book. As we read God's Word together my prayer is that the Holy Spirit authenticate Scripture to us by His inward witness, open our minds to understand its meaning, and move our wills to obey its commands, thus sanctifying our lives for God's glory and our good."
            : "Aștept cu nerăbdare să citim împreună Cuvântul Sfânt al lui Dumnezeu; cuvânt cu cuvânt, verset cu verset, capitol cu capitol și carte cu carte. Pe măsură ce citim Cuvântul lui Dumnezeu împreună, rugăciunea mea este ca Duhul Sfânt să autentifice Scriptura pentru noi prin mărturia Sa interioară, să ne deschidă mințile pentru a înțelege semnificația ei și să miște voințele noastre pentru a asculta poruncile Sale, sfințind astfel viețile noastre pentru gloria lui Dumnezeu și binele nostru."}
        </Typography>
      </div>

      <Typography style={{ ...introNonParagraphStyle, marginBottom: "0px" }}>
        Leonard Semenea
      </Typography>

      <Typography style={{ ...introNonParagraphStyle, marginBottom: "0px" }}>
        {language === EN ? "Senior Pastor" : "Pastor Senior"}
      </Typography>

      <Typography style={{ ...introNonParagraphStyle, fontSize: "12px" }}>
        {language === EN
          ? "[Adapted from the Chicago Statement on Biblical Inerrancy]"
          : "[Adaptat de la Declarația de la Chicago despre Inerabilitatea Biblică]"}
      </Typography>
      <Divider light width="100%" />

      {/* January with all 31 days */}
      {renderMonth("January", [
        "January 1",
        "January 2",
        "January 3",
        "January 4",
        "January 5",
        "January 6",
        "January 7",
        "January 8",
        "January 9",
        "January 10",
        "January 11",
        "January 12",
        "January 13",
        "January 14",
        "January 15",
        "January 16",
        "January 17",
        "January 18",
        "January 19",
        "January 20",
        "January 21",
        "January 22",
        "January 23",
        "January 24",
        "January 25",
        "January 26",
        "January 27",
        "January 28",
        "January 29",
        "January 30",
        "January 31",
      ])}

      {renderMonth("February", [
        "February 1",
        "February 2",
        "February 3",
        "February 4",
        "February 5",
        "February 6",
        "February 7",
        "February 8",
        "February 9",
        "February 10",
        "February 11",
        "February 12",
        "February 13",
        "February 14",
        "February 15",
        "February 16",
        "February 17",
        "February 18",
        "February 19",
        "February 20",
        "February 21",
        "February 22",
        "February 23",
        "February 24",
        "February 25",
        "February 26",
        "February 27",
        "February 28",
      ])}

      {renderMonth("March", [
        "March 1",
        "March 2",
        "March 3",
        "March 4",
        "March 5",
        "March 6",
        "March 7",
        "March 8",
        "March 9",
        "March 10",
        "March 11",
        "March 12",
        "March 13",
        "March 14",
        "March 15",
        "March 16",
        "March 17",
        "March 18",
        "March 19",
        "March 20",
        "March 21",
        "March 22",
        "March 23",
        "March 24",
        "March 25",
        "March 26",
        "March 27",
        "March 28",
        "March 29",
        "March 30",
        "March 31",
      ])}

      {renderMonth("April", [
        "April 1",
        "April 2",
        "April 3",
        "April 4",
        "April 5",
        "April 6",
        "April 7",
        "April 8",
        "April 9",
        "April 10",
        "April 11",
        "April 12",
        "April 13",
        "April 14",
        "April 15",
        "April 16",
        "April 17",
        "April 18",
        "April 19",
        "April 20",
        "April 21",
        "April 22",
        "April 23",
        "April 24",
        "April 25",
        "April 26",
        "April 27",
        "April 28",
        "April 29",
        "April 30",
      ])}

      {renderMonth("May", [
        "May 1",
        "May 2",
        "May 3",
        "May 4",
        "May 5",
        "May 6",
        "May 7",
        "May 8",
        "May 9",
        "May 10",
        "May 11",
        "May 12",
        "May 13",
        "May 14",
        "May 15",
        "May 16",
        "May 17",
        "May 18",
        "May 19",
        "May 20",
        "May 21",
        "May 22",
        "May 23",
        "May 24",
        "May 25",
        "May 26",
        "May 27",
        "May 28",
        "May 29",
        "May 30",
        "May 31",
      ])}

      {renderMonth("June", [
        "June 1",
        "June 2",
        "June 3",
        "June 4",
        "June 5",
        "June 6",
        "June 7",
        "June 8",
        "June 9",
        "June 10",
        "June 11",
        "June 12",
        "June 13",
        "June 14",
        "June 15",
        "June 16",
        "June 17",
        "June 18",
        "June 19",
        "June 20",
        "June 21",
        "June 22",
        "June 23",
        "June 24",
        "June 25",
        "June 26",
        "June 27",
        "June 28",
        "June 29",
        "June 30",
      ])}

      {renderMonth("July", [
        "July 1",
        "July 2",
        "July 3",
        "July 4",
        "July 5",
        "July 6",
        "July 7",
        "July 8",
        "July 9",
        "July 10",
        "July 11",
        "July 12",
        "July 13",
        "July 14",
        "July 15",
        "July 16",
        "July 17",
        "July 18",
        "July 19",
        "July 20",
        "July 21",
        "July 22",
        "July 23",
        "July 24",
        "July 25",
        "July 26",
        "July 27",
        "July 28",
        "July 29",
        "July 30",
        "July 31",
      ])}

      {renderMonth("August", [
        "August 1",
        "August 2",
        "August 3",
        "August 4",
        "August 5",
        "August 6",
        "August 7",
        "August 8",
        "August 9",
        "August 10",
        "August 11",
        "August 12",
        "August 13",
        "August 14",
        "August 15",
        "August 16",
        "August 17",
        "August 18",
        "August 19",
        "August 20",
        "August 21",
        "August 22",
        "August 23",
        "August 24",
        "August 25",
        "August 26",
        "August 27",
        "August 28",
        "August 29",
        "August 30",
        "August 31",
      ])}

      {renderMonth("September", [
        "September 1",
        "September 2",
        "September 3",
        "September 4",
        "September 5",
        "September 6",
        "September 7",
        "September 8",
        "September 9",
        "September 10",
        "September 11",
        "September 12",
        "September 13",
        "September 14",
        "September 15",
        "September 16",
        "September 17",
        "September 18",
        "September 19",
        "September 20",
        "September 21",
        "September 22",
        "September 23",
        "September 24",
        "September 25",
        "September 26",
        "September 27",
        "September 28",
        "September 29",
        "September 30",
      ])}

      {renderMonth("October", [
        "October 1",
        "October 2",
        "October 3",
        "October 4",
        "October 5",
        "October 6",
        "October 7",
        "October 8",
        "October 9",
        "October 10",
        "October 11",
        "October 12",
        "October 13",
        "October 14",
        "October 15",
        "October 16",
        "October 17",
        "October 18",
        "October 19",
        "October 20",
        "October 21",
        "October 22",
        "October 23",
        "October 24",
        "October 25",
        "October 26",
        "October 27",
        "October 28",
        "October 29",
        "October 30",
        "October 31",
      ])}

      {renderMonth("November", [
        "November 1",
        "November 2",
        "November 3",
        "November 4",
        "November 5",
        "November 6",
        "November 7",
        "November 8",
        "November 9",
        "November 10",
        "November 11",
        "November 12",
        "November 13",
        "November 14",
        "November 15",
        "November 16",
        "November 17",
        "November 18",
        "November 19",
        "November 20",
        "November 21",
        "November 22",
        "November 23",
        "November 24",
        "November 25",
        "November 26",
        "November 27",
        "November 28",
        "November 29",
        "November 30",
      ])}

      {renderMonth("December", [
        "December 1",
        "December 2",
        "December 3",
        "December 4",
        "December 5",
        "December 6",
        "December 7",
        "December 8",
        "December 9",
        "December 10",
        "December 11",
        "December 12",
        "December 13",
        "December 14",
        "December 15",
        "December 16",
        "December 17",
        "December 18",
        "December 19",
        "December 20",
        "December 21",
        "December 22",
        "December 23",
        "December 24",
        "December 25",
        "December 26",
        "December 27",
        "December 28",
        "December 29",
        "December 30",
        "December 31",
      ])}

      {showScrollTop && (
        <Button
          onClick={scrollTop}
          style={scrollTopButtonStyle}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<ArrowUpwardIcon />}
        >
          {language === EN ? "Scroll to top" : "Derulați în Sus"}
        </Button>
      )}

      {showScrollToToday && (
        <Button
          onClick={scrollToTodayVerse}
          style={scrollCurrentButtonStyle}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<TodayIcon />}
        >
          {language === EN
            ? "Scroll to today's passage"
            : "Derulați la pasajul de astăzi"}
        </Button>
      )}

      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default BiblePlan;
